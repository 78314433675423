import {cloneElement, Children, useCallback, useState, useEffect, useRef} from 'react'
import clsx from 'clsx'
import {ReactComponent as CloseIcon} from 'assets/images/close.svg'
import Button from './Button'

const SharePageModal = (props) => {
  // window is not available in node (used for Server-side rendering) so need to check it is defined before using it
  const defaultShareURL = typeof window === 'undefined' ? '' : window.location.href
  const {id = 'shareJobModal', shareUrl = defaultShareURL, children, ...other} = props

  const [copySuccess, setCopySuccess] = useState(false)

  const copyShareUrl = useCallback(() => {
    navigator.clipboard.writeText(shareUrl)
    setCopySuccess(true)
  }, [shareUrl])

  useEffect(() => {
    if (copySuccess) {
      // reset copy success back to false after 1sec when it is set to true
      const timeout = window.setTimeout(() => {
        setCopySuccess(false)
      }, 2000)
      return () => {
        // cancel timout if component is unmounted or copySuccess has been set back to false already
        window.clearTimeout(timeout)
      }
    }
  }, [copySuccess])

  const modalRef = useRef()
  useEffect(() => {
    const modal = modalRef.current
    const handleShow = () => {
      modal.querySelector('#copy-button').focus({preventScroll: true})
    }
    modal.addEventListener('shown.bs.modal', handleShow)
    return () => {
      modal.removeEventListener('shown.bs.modal', handleShow)
    }
  })

  return (
    <>
      {cloneElement(Children.only(children), {'data-bs-toggle': 'modal', 'data-bs-target': `#${id}`})}

      <div className="modal fade" id={id} tabIndex="-1" role="dialog" ref={modalRef}
           aria-labelledby="shareJobModel_title" aria-hidden="true" aria-modal="true" {...other}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <Button className="text-primary text-hover-dark border-0 position-absolute top-0 end-0 p-0 lh-1 m-1"
                    data-bs-dismiss="modal" aria-label="Close">
              <CloseIcon />
            </Button>
            <div className="modal-header border-0 justify-content-center">
              <h3 className="modal-title text-dark" id="shareJobModel_title">Share this job listing</h3>
            </div>
            <div className="modal-body p-5 pt-0">
              <div className="input-group input-group-sm">
                <input type="text" className="form-control bg-white" readOnly
                       aria-label="Share URL" value={shareUrl} />
                <Button
                aria-label="Copy"
                color="primary"
                className={clsx(copySuccess && 'btn-success bg-success')}
                id="copy-button"
                onClick={copyShareUrl}
              >
                  {copySuccess ? 'Copied' : 'Copy'}
                </Button>
                <div className="visually-hidden" aria-live="polite">
                  {copySuccess && 'Copied'}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default SharePageModal
