import {Helmet} from 'react-helmet-async'
import socialImageUrl from 'assets/images/social-image.png'
import {compact} from 'lodash-es'

const SITE_NAME = 'Kimi Mahi Mai Find a Job'

const Meta = (props) => {
  const {title, description, type = 'website', imageUrl = socialImageUrl, status} = props
  const pageTitle = compact([title, SITE_NAME]).join(' | ')

  // Helmet adds its children to the pages head.
  return (
    <Helmet>
      <title>{pageTitle}</title>
      <meta property="og:title" content={pageTitle} />
      {description && <meta name="description" content={description} />}
      {description && <meta property="og:description" content={description} />}
      <meta property="og:site_name" content={SITE_NAME} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:type" content={type} />
      {status && <meta name="faj:status" content={status} />}
    </Helmet>
  )
}

export default Meta
