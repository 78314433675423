import {StrictMode} from 'react'
import {hydrateRoot} from 'react-dom/client'
import {HelmetProvider} from 'react-helmet-async'
import App from './App'
import reportWebVitals from './reportWebVitals'

// hydrateRoot attaches the react tree to prerendered html. When we prerender we also add a script that adds the
// prerender data to window.appData so the data is available to the react tree once it mounts

hydrateRoot(
  document.getElementById('root'),
  <StrictMode>
    <HelmetProvider>
      <App data={window.appData} />
    </HelmetProvider>
  </StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
